<template>
  <AccountingLayout :cardStats="cardStats" :statsTitle="statsTitle">
    <template #dataTable>
      <AccountingDataTable :data="classData" :columns="columns" />
    </template>
  </AccountingLayout>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import AccountingLayout from "@/components/AccountingLayout.vue";
import AccountingDataTable from "@/components/AccountingDataTable.vue";

export default {
  components: { User, Entete, AccountingDataTable, AccountingLayout },
  data() {
    return {
      cardStats: [],
      statsTitle: "Recap 2022",
      classData: [],
      columns: [],
    };
  },
  computed: {
    accounts() {
      return this.$store.getters["accounting/getClassAccounts"];
    },
  },
  methods: {
    getCourseCost(course) {
      return course.prix_enseignant * course.nombre_heure;
    },

    async initColumsData() {
      this.columns = [
        {
          field: "course",
          header: "Matière",
          sortable: true,
        },
        {
          field: "teacher",
          header: "Enseignant",
          sortable: true,
        },
        {
          field: "hours",
          header: "Nombre d'heure",
          sortable: true,
        },
        {
          field: "cost",
          header: "Coût mensuel en Fcfa",
          sortable: true,
        },
      ];
    },
  },
  async mounted() {
    this.cardStats = [
      {
        title: "Matière la plus couteuse",
        subTitle: "Tle D",
        value: 1200000,
        currency: "Fcfa",
      },
      {
        title: "Matière la moins couteuse",
        subTitle: "6ème M1",
        value: 700000,
        currency: "Fcfa",
      },
      {
        title: "Matière la plus couteuse",
        subTitle: "6ème M1",
        value: 200000,
        currency: "Fcfa",
      },
    ];
    await this.$store
      .dispatch(
        "accounting/getClasseAccountingDetatails",
        this.$route.params.id
      )
      .then((res) => {
        this.statsTitle = this.statsTitle + ` ${res.classe.libelle}`;
        res.matieres.map((course) => {
          const cost = this.getCourseCost(course);
          let obj = {
            id: res.classe.id,
            course: course.matiere.libelle,
            cost,
            teacher: `${course.enseignant.first_name} ${course.enseignant.last_name}`,
            hours: course.nombre_heure,
          };

          this.classData.push(obj);
        });
        this.initColumsData();
      });
  },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}
</style>