<template>
  <div class="login">
    <div class="row justify-content-center">
      <div class="col-6 shadow block">
        <div class="row">
          <div class="col bg-login">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="col">
            <h5 class="fw-bold mt-1">Connexion</h5>

            <form @submit="login" class="p-4 text-start">
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label text-start fw-bold"
                  >Email address</label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  id="exampleInputEmail1"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label fw-bold"
                  >Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  id="exampleInputPassword1"
                />
              </div>

              <div v-if="loader" class="spinner-grow ml-4" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button v-else type="submit" class="btn btn-color form-control">
                Connexion
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      loader: false,
    };
  },

  methods: {
    login(e) {
      e.preventDefault();

      let data = { email: this.email, password: this.password };
      this.loader = true;
      this.$store
        .dispatch("auth/login", data)
        .then((res) => {
          this.loader = false;
          if (res.status === 200) this.$router.push({ name: "home" });
          console.log(res);
        })
        .catch((res) => {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "nom d'utilisateur ou mot de passe incorrect",
            life: 3000,
          });

          this.loader = false;
          console.log(res);
        });
    },
  },
};
</script>

<style scoped>
.bg-login {
  background-color: #efefef;
}
input {
  background-color: #efefef;
}

img {
  margin-top: 38%;
  /* transform: translateY(100px); */
}

.block {
  transform: translateY(40%);
}
.btn-color {
  background-color: #5e00bc;
  color: white;
  font-weight: bold;
}
</style>
