<template>
  <div class="header-left">
    <div class="row p-2">
      <div class="col-12 col-md" v-for="item in data" :key="item.id">
        <Stat :infos="item" />
      </div>
    </div>
  </div>
</template>

<script>
import Stat from "./Stat.vue";
export default {
  components: { Stat },
  data() {
    return {
      data: [
        {
          id: 1,
          type: "éleves",
          nombre: "3,800",
          img: require("@/assets/img/stat1.png"),
        },
        {
          id: 2,
          type: "parents",
          nombre: "1,200",
          img: require("@/assets/img/stat2.png"),
        },
        {
          id: 3,
          type: "enseignants",
          nombre: 123,
          img: require("@/assets/img/stat3.png"),
        },
      ],
    };
  },
};
</script>
