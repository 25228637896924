import axios from "axios";

export default {
  namespaced: true,

  state: {
    plannings: [
      ["", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
      ["7h30:8h30", "", "", "", "", ""],
      ["8h30:9h30", "", "", "", "", ""],
      ["9h30:10h30", "", "", "", "", ""],
      ["9h30:10h30", "", "", "", "", ""],
      ["11h30:12h30", "", "", "", "", ""],
    ],
  },
  getters: {
    getAllClasses: (state) => {
      return state.classes;
    },
  },
  mutations: {
    setCoursePeriod(state, data) {
      // let days = [
      //   "Dimanche",
      //   "Lundi",
      //   "Mardi",
      //   "Mercredi",
      //   "Jeudi",
      //   "Vendredi",
      //   "Samedi",
      // ];

      // let defaul = {
      //   id: "",
      //   jour: "",
      //   h_debut: "",
      //   h_fin: "",
      //   matiere: "",
      // };

      // let filter = [];

      // data.forEach((item) => {
      //   let obj = {
      //     id: item.id,
      //     jour: days[new Date(item.jour).getDay()],
      //     h_debut: item.h_debut,
      //     h_fin: item.h_fin,
      //     matiere: item.classe_matiere.matiere.libelle,
      //     id_matiere: item.classe_matiere.matiere.id,
      //   };

      //   filter.push(obj);
      // });

      // let Lundi,
      //   Mardi,
      //   Mercredi,
      //   Jeudi,
      //   Vendredi = [];

      // Lundi = filter.filter((item) => item.jour === "Lundi");
      // Mardi = filter.filter((item) => item.jour === "Mardi");
      // Mercredi = filter.filter((item) => item.jour === "Mercredi");
      // Jeudi = filter.filter((item) => item.jour === "Jeudi");
      // Vendredi = filter.filter((item) => item.jour === "Vendredi");

      // console.log(filter);

      // Lundi.sort((prev, next) => {
      //   return (
      //     Number(prev.h_debut.replace(":", "")) -
      //     Number(next.h_debut.replace(":", ""))
      //   );
      // });
      // Mardi.sort((prev, next) => {
      //   return (
      //     Number(prev.h_debut.replace(":", "")) -
      //     Number(next.h_debut.replace(":", ""))
      //   );
      // });
      // Mercredi.sort((prev, next) => {
      //   return (
      //     Number(prev.h_debut.replace(":", "")) -
      //     Number(next.h_debut.replace(":", ""))
      //   );
      // });
      // Jeudi.sort((prev, next) => {
      //   return (
      //     Number(prev.h_debut.replace(":", "")) -
      //     Number(next.h_debut.replace(":", ""))
      //   );
      // });

      // Vendredi.sort((prev, next) => {
      //   return (
      //     Number(prev.h_debut.replace(":", "")) -
      //     Number(next.h_debut.replace(":", ""))
      //   );
      // });

      // state.plannings.map((item, index) => {
      //   if (index > 0) {
      //     for (let i = 0; i < item.length; i++) {
      //       switch (i) {
      //         case 1:
      //           item[i] = Lundi[index - 1] ? Lundi[index - 1] : defaul;
      //           break;
      //         case 2:
      //           item[i] = Mardi[index - 1] ? Mardi[index - 1] : defaul;
      //           break;
      //         case 3:
      //           item[i] = Mercredi[index - 1] ? Mercredi[index - 1] : defaul;
      //           break;
      //         case 4:
      //           item[i] = Jeudi[index - 1] ? Jeudi[index - 1] : defaul;
      //           break;
      //         case 5:
      //           item[i] = Vendredi[index - 1] ? Vendredi[index - 1] : defaul;
      //           break;

      //         default:
      //           break;
      //       }
      //     }
      //   }
      // });

      let days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      let defaul = {
        id: "",
        jour: "",
        h_debut: "",
        h_fin: "",
        matiere: "",
      };

      let filter = [];

      data.forEach((item) => {
        let obj = {
          id: item.id,
          jour: days[new Date(item.jour).getDay()],
          h_debut: item.h_debut,
          h_fin: item.h_fin,
          matiere: item.classe_matiere.matiere.libelle,
          id_matiere: item.classe_matiere.matiere.id,
        };

        filter.push(obj);
      });

      let keys = [];

      filter.forEach((item, index) => {
        let tempo = `${item.h_debut}-${item.h_fin}`;
        if (!keys.includes(tempo)) keys.push(tempo);
      });

      let object = {};
      keys.forEach((item, index) => {
        let tempo = filter.filter(
          (elt) => `${elt.h_debut}-${elt.h_fin}` === item
        );

        object[item] = tempo;
      });

      let sortedKeys = keys.sort();

      function sortPlanning(data, time) {
        let tempo = [defaul, defaul, defaul, defaul, defaul, defaul, defaul];

        data.forEach((item, index) => {
          tempo[0] = time;
          switch (item.jour.toLowerCase()) {
            case "lundi":
              tempo[1] = item;
              break;
            case "mardi":
              tempo[2] = item;
              break;
            case "mercredi":
              tempo[3] = item;
              break;
            case "jeudi":
              tempo[4] = item;
              break;
            case "vendredi":
              tempo[5] = item;
              break;
            case "samedi":
              tempo[6] = item;
              break;

            default:
              break;
          }
        });

        return tempo;
      }

      sortedKeys.forEach((item, index) => {
        object[item] = sortPlanning(object[item], item);
      });

      let finalPlan = [];

      days[0] = "";

      finalPlan.push(days);

      sortedKeys.forEach((item) => {
        finalPlan.push(object[item]);
      });

      console.log("---plan ", finalPlan);
      state.plannings = finalPlan;
    },
  },
  actions: {
    addCoursePeriod({ commit }, data) {
      return axios
        .post(
          `classes/${data.class_id}/matieres/${data.id_course}/planning`,
          data
        )
        .then((res) => {
          // commit("setCoursePeriod", res.data);
          return res;
        });
    },
    allPlanning({ commit }, id_classe) {
      return axios.get(`classes/${id_classe}/planning`).then((res) => {
        commit("setCoursePeriod", res.data);
        return res;
      });
    },
  },
};
