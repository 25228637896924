<template>
  <div class="stat">
    <div class="row mt-2">
      <div class="col">
        <p>{{ infos.type }}</p>
        <p>{{ infos.nombre }}</p>
      </div>

      <div class="col">
        <img class="size-img" :src="infos.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["infos"],
  setup() {},
};
</script>

<style scoped>
.stat {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;

  border: 1px solid #eaecee;
  border-radius: 12px;
}

.size-img {
  height: 50px;
  width: 50px;
}
</style>
