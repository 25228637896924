<template>
  <div id="wrapper">
    <nav
      class="navbar navbar-inverse fixed-top"
      id="sidebar-wrapper"
      role="navigation"
    >
      <ul class="nav sidebar-nav">
        <div class="sidebar-header">
          <div class="sidebar-brand">
            <router-link :to="{ name: 'home' }"
              ><img src="@/assets/logo.png" alt="" />
            </router-link>
          </div>
        </div>
        <li>
          <router-link
            :class="active === 'student' ? 'active-class' : ''"
            :to="{ name: 'student' }"
            >Eleves</router-link
          >
        </li>
        <li>
          <router-link
            :class="active === 'parent' ? 'active-class' : ''"
            :to="{ name: 'parent' }"
            >Parents</router-link
          >
        </li>
        <li>
          <router-link
            :class="active === 'tcheacher' ? 'active-class' : ''"
            :to="{ name: 'tcheacher' }"
            >Enseignants</router-link
          >
        </li>
        <li>
          <router-link
            :class="active === 'class' ? 'active-class' : ''"
            :to="{ name: 'class' }"
            >Classes</router-link
          >
        </li>
        <!-- <li class="dropdown">
          <a href="#works" class="dropdown-toggle" data-toggle="dropdown"
            >Works <span class="caret"></span
          ></a>
          <ul class="dropdown-menu animated fadeInLeft" role="menu">
            <div class="dropdown-header">Dropdown heading</div>
            <li><a href="#pictures">Pictures</a></li>
            <li><a href="#videos">Videeos</a></li>
            <li><a href="#books">Books</a></li>
            <li><a href="#art">Art</a></li>
            <li><a href="#awards">Awards</a></li>
          </ul>
        </li> -->
        <li>
          <router-link
            :class="active === 'course' ? 'active-class' : ''"
            :to="{ name: 'course' }"
            >Matières</router-link
          >
        </li>
        <li>
          <router-link
            :class="active === 'appel' ? 'active-class' : ''"
            :to="{ name: 'appel' }"
            >Appels</router-link
          >
        </li>
        <li>
          <router-link
            :class="active === 'accounting' ? 'active-class' : ''"
            :to="{ name: 'accounting' }"
            >Comptabilité</router-link
          >
        </li>
      </ul>
    </nav>

    <div id="page-content-wrapper">
      <button
        type="button"
        class="hamburger animated fadeInLeft is-closed"
        data-toggle="offcanvas"
        @click="test"
      >
        <span class="hamb-top"></span>
        <span class="hamb-middle"></span>
        <span class="hamb-bottom"></span>
      </button>
      <div class="container">
        <div class="row">
          <div :class="appClass">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appClass: "col-lg-12 col-lg-offset-2",
      open: false,
    };
  },

  methods: {
    test() {
      this.open = !this.open;
      this.appClass = this.open
        ? "col-lg-10 col-lg-offset-2"
        : "col-lg-12 col-lg-offset-2";

      // if (this.open) {
      //   this.appClass = "col-lg-10 col-lg-offset-2";
      // } else {
      //   this.appClass = ;
      // }
    },
    initialise() {
      $(document).ready(function () {
        var trigger = $(".hamburger"),
          overlay = $(".overlay"),
          isClosed = false;

        trigger.click(function () {
          hamburger_cross();
        });

        function hamburger_cross() {
          if (isClosed == true) {
            overlay.hide();
            trigger.removeClass("is-open");
            trigger.addClass("is-closed");
            isClosed = false;
          } else {
            overlay.show();
            trigger.removeClass("is-closed");
            trigger.addClass("is-open");
            isClosed = true;
          }
        }

        $('[data-toggle="offcanvas"]').click(function () {
          $("#wrapper").toggleClass("toggled");
        });
      });
    },
  },

  computed: {
    active() {
      return this.$route.name;
    },
  },
  mounted() {
    this.initialise();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #000000 !important;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* style for side bar */
body {
  position: relative;
  overflow-x: hidden;
  background-color: #fff;
}
body,
html {
  height: 100%;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/

.active-class {
  border-radius: 17px;
  background-color: #5e00bc !important;
  color: white !important;
}
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 220px;
}

#sidebar-wrapper {
  z-index: 1000;
  left: 220px;
  width: 0;
  height: 100%;
  margin-left: -220px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
  width: 220px;
}

#page-content-wrapper {
  width: 100%;
  padding-top: 70px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -220px;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.navbar {
  padding: 0;
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 100%;
}

.sidebar-nav li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}
/* .sidebar-nav li:hover {
  background: #5e00bc !important;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
} */
/* .sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
  width: 100%;
  -webkit-transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
} */

/* text color side bar */
.sidebar-nav li a {
  display: block;
  color: #000;
  background-color: #f9f9f9;
  text-decoration: none;
  padding: 10px 15px 10px 30px;
  text-align: start;
  margin-top: 20px;
  margin-left: 10px;
  border-radius: 17px;
}

.sidebar-nav li a:hover {
  border-radius: 17px;
  background-color: #5e00bc !important;
  color: white !important;
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.sidebar-header {
  text-align: center;
  font-size: 20px;
  position: relative;
  width: 100%;
  display: inline-block;
}
.sidebar-brand {
  height: 65px;
  position: relative;
  color: #0000;
  background: linear-gradient(to right bottom, #ffffff 50%, #ffffff 50%);
  padding-top: 1em;
}
.sidebar-brand a {
  color: #ddd;
}
.sidebar-brand a:hover {
  color: #fff;
  text-decoration: none;
}
.dropdown-header {
  text-align: center;
  font-size: 1em;
  color: #ddd;
  background: #ffffff;
  background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
}
.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
}
.dropdown-menu.show {
  top: 0;
}
/*Fontawesome icons*/
.nav.sidebar-nav li a::before {
  font-family: fontawesome;
  content: "\f12e";
  vertical-align: baseline;
  display: inline-block;
  padding-right: 5px;
}
a[href*="/student"]::before {
  content: "\f19d" !important;
}
a[href*="/parent"]::before {
  content: "\e53a" !important;
}
a[href*="/tcheacher"]::before {
  content: "\f4ff" !important;
}
/* a[href*="#enseignants"]::before {
  content: "\f073" !important;
}
a[href*="#classes"]::before {
  content: "\f549" !important;
}
a[href*="#works"]::before {
  content: "\f0b1" !important;
}
a[href*="#pictures"]::before {
  content: "\f03e" !important;
}
a[href*="#videos"]::before {
  content: "\f03d" !important;
}
a[href*="#books"]::before {
  content: "\f02d" !important;
}
a[href*="/art"]::before {
  content: "\f1fc" !important;
} */
a[href*="/class"]::before {
  content: "\f549" !important;
}
a[href*="/course"]::before {
  content: "\f518" !important;
}
a[href*="/appel"]::before {
  content: "\f0ae" !important;
}
/* a[href*="#followme"]::before {
  content: "\f099" !important;
  color: #0084b4;
} */
/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
  position: fixed;
  top: 20px;
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all 0.35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}
.hamburger.is-open .hamb-middle {
  display: none;
}
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}
.hamburger.is-open:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/
</style>
