<template>
  <div class="my-3">
    <div class="card">
      <!-- Entête de la table ------------------------ -->

      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedtcheachers || !selectedtcheachers.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="tcheachers"
        v-model:selection="selectedtcheachers"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tcheachers"
        responsiveLayout="scroll"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justiify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Liste des Enseignants</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Search..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        ></Column>

        <!-- <Column
          field="picture"
          header="Photo"
          :sortable="true"
          style="min-width: 16rem"
        ></Column> -->
        <Column header="Photo">
          <template #body="slotProps">
            <img
              src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
              :alt="slotProps.data.name"
              class="tcheacher-image"
            />
          </template>
        </Column>
        <Column
          field="first_name"
          header="Nom"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.first_name) }}
          </template>
        </Column>
        <Column
          field="last_name"
          header="Prenom"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.last_name) }}
          </template>
        </Column>
        <Column
          field="gender"
          header="Sexe"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            <span>
              <Strong> {{ slotProps.data.gender }} </Strong></span
            >
            <!-- <span v-else> <strong>F</strong></span> -->
          </template>
        </Column>
        <Column
          field="phone"
          header="Téléphone"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.phone) }}
          </template>
        </Column>

        <Column
          field="email"
          header=" Email"
          :sortable="true"
          style="min-width: 12rem"
        >
          <template #body="slotProps">
            <span class="p-2">{{ slotProps.data.email }}</span>
          </template>
        </Column>
        <Column header="Actions" :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="edittcheacher(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeletetcheacher(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Modal edit tcheacher ------- And create tcheacher --------------------------------------------------------------------------- -->
    <Dialog
      v-model:visible="enseignantDialog"
      :style="{ width: '450px' }"
      header="tcheacher Details"
      :modal="true"
      class="p-fluid"
    >
      <img
        src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
        :alt="tcheacher.image"
        class="tcheacher-image"
        v-if="tcheacher.image"
      />
      <div class="field">
        <label for="name">Nom</label>
        <InputText
          id="name"
          v-model.trim="tcheacher.first_name"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !tcheacher.first_name }"
        />
        <small class="p-error" v-if="submitted && !tcheacher.first_name"
          >Name is required.</small
        >
      </div>
      <div class="field">
        <label for="prenom">Prenom</label>
        <InputText id="prenom" v-model="tcheacher.last_name" required="true" />
      </div>

      <div class="field">
        <!-- <label for="inventoryStatus" class="mb-3">Inventory Status</label>
        <Dropdown
          id="inventoryStatus"
          v-model="tcheacher.inventoryStatus"
          :options="statuses"
          optionLabel="label"
          placeholder="Select a Status"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'tcheacher-badge status-' + slotProps.value.value">{{
                slotProps.value.label
              }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span
                :class="'tcheacher-badge status-' + slotProps.value.toLowerCase()"
                >{{ slotProps.value }}</span
              >
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
        </Dropdown> -->
      </div>

      <!-- <div class="field">
        <label class="mb-3">Category</label>
        <div class="formgrid grid">
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category1"
              name="category"
              value="Accessories"
              v-model="tcheacher.category"
            />
            <label for="category1">Accessories</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category2"
              name="category"
              value="Clothing"
              v-model="tcheacher.category"
            />
            <label for="category2">Clothing</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category3"
              name="category"
              value="Electronics"
              v-model="tcheacher.category"
            />
            <label for="category3">Electronics</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category4"
              name="category"
              value="Fitness"
              v-model="tcheacher.category"
            />
            <label for="category4">Fitness</label>
          </div>
        </div>
      </div> -->

      <div class="field">
        <label for="phone">Telephone</label>
        <InputNumber id="phone" v-model="tcheacher.phone" required="true" />
      </div>

      <div class="field">
        <label for="phone">Nationalité</label>
        <Dropdown
          id="phone"
          v-model="tcheacher.nationality"
          :options="nationalityList"
          optionLabel="name"
        />
      </div>

      <div class="field">
        <label for="ville">Ville</label>
        <InputText id="ville" v-model="tcheacher.city" required="true" />
      </div>

      <div class="field">
        <label for="sexe">Sexe</label>
        <Dropdown
          id="sexe"
          v-model="tcheacher.gender"
          :options="sexList"
          required="true"
          optionLabel="name"
        />
      </div>

      <div class="field">
        <label for="email">Email</label>
        <InputText
          id="email"
          type="email"
          v-model="tcheacher.email"
          required="true"
        />
      </div>

      <div class="field">
        <label for="password">Mot de passe</label>
        <Password id="password" v-model="tcheacher.password" required="true" />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          v-if="!loadAddAndDeletetcheacher"
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="savetcheacher"
        />

        <ProgressSpinner
          v-else
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </template>
    </Dialog>

    <!-- Modal for confirmation deleted one  tcheacher ----------------------------------------------------- -->
    <Dialog
      v-model:visible="deleteenseignantDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="tcheacher"
          >Voulez vous vraiment supprimer <b>{{ tcheacher.first_name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteenseignantDialog = false"
        />
        <Button
          v-if="!loadDeletetcheacher"
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletetcheacher"
        />
        <ProgressSpinner
          v-else
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </template>
    </Dialog>
    <!-- Modal for confirmation deleted Multiple tcheacher  tcheacher ----------------------------------------------------- -->
    <Dialog
      v-model:visible="deletetcheachersDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="tcheacher"
          >Voulez vous vraiment supprimer cet Enseignant?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deletetcheachersDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedtcheachers"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
// import tcheacherService from "./service/tcheacherService";

import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Rating from "primevue/rating";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import students from "../../datas.json";

export default {
  components: {
    Toolbar,
    FileUpload,
    DataTable,
    InputText,
    Column,
    Rating,
    Dialog,
    Dropdown,
    RadioButton,
    Textarea,
    Password,
    InputNumber,
  },
  data() {
    return {
      // tcheachers: null,
      sexList: [
        { name: "M", code: "M" },
        { name: "F", code: "F" },
      ],
      nationalityList: [{ name: "Camerounaise", code: "Camerounaise" }],
      enseignantDialog: false,
      deleteenseignantDialog: false,
      deletetcheachersDialog: false,
      tcheacher: {},

      selectedtcheachers: null,
      filters: {},
      submitted: false,

      loadAddAndDeletetcheacher: false,
      loadDeletetcheacher: false,

      data: students,
    };
  },

  computed: {
    tcheachers() {
      return this.$store.getters["teatcher/getAll"];
    },
  },

  created() {
    // this.tcheacherService = new tcheacherService();
    this.initFilters();
  },
  mounted() {
    this.gettcheachers();
  },
  methods: {
    gettcheachers() {
      this.$store
        .dispatch("teatcher/getAll")
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },

    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.tcheacher = {};
      this.submitted = false;
      this.enseignantDialog = true;
    },
    hideDialog() {
      this.enseignantDialog = false;
      this.submitted = false;
    },

    //method to save and update tcheacher
    savetcheacher() {
      this.submitted = true;

      let data = {};
      let dispatcher = "";
      if (this.tcheacher.first_name.trim()) {
        this.loadAddAndDeletetcheacher = true;
        if (this.tcheacher.id) {
          dispatcher = "teatcher/updateTeatcher";
          data = {
            first_name: this.tcheacher.first_name,
            last_name: this.tcheacher.last_name,
            phone: this.tcheacher.phone,
            nationality: this.tcheacher.nationality.code,
            city: this.tcheacher.city,
            gender: this.tcheacher.gender.code,
            roles: ["enseignant"],
            email: this.tcheacher.email,
            password: this.tcheacher.password,
            password_confirmation: this.tcheacher.password,
          };
        } else {
          dispatcher = "teatcher/createTeatcher";

          data = {
            first_name: this.tcheacher.first_name,
            last_name: this.tcheacher.last_name,
            phone: this.tcheacher.phone,
            nationality: this.tcheacher.nationality.code,
            city: this.tcheacher.city,
            gender: this.tcheacher.gender.code,
            roles: ["enseignant"],
            email: this.tcheacher.email,
            password: this.tcheacher.password,
            password_confirmation: this.tcheacher.password,
          };
        }

        console.log("data to se,d ", data);

        this.$store
          .dispatch(dispatcher, data)
          .then((res) => {
            console.log(res);
            this.$toast.add({
              severity: "success",
              summary: "Succes",
              detail: "Opération réussi",
              life: 3000,
            });
            this.loadAddAndDeletetcheacher = false;
            this.enseignantDialog = false;
            this.tcheacher = {};
          })
          .catch((err) => {
            this.loadAddAndDeletetcheacher = false;
            this.$toast.add({
              severity: "error",
              summary: "Attention",
              detail: "Vous ne pouvez pas effectuer cette opération ",
              life: 3000,
            });
            console.log(err);
          });
      }
    },
    edittcheacher(tcheacher) {
      this.tcheacher = { ...tcheacher };
      this.enseignantDialog = true;
    },

    //method to handle modal to confirm delete tcheacher
    confirmDeletetcheacher(tcheacher) {
      this.tcheacher = tcheacher;
      this.deleteenseignantDialog = true;
    },

    //method for delete signgle tcheacher tcheacher
    deletetcheacher() {
      // this.tcheachers = this.tcheachers.filter((val) => val.id !== this.tcheacher.id);
      this.loadDeletetcheacher = true;

      this.$store
        .dispatch("teatcher/deleteTeatcher", this.tcheacher.id)
        .then((res) => {
          console.log(res);

          this.loadDeletetcheacher = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Enseignant supprimé ",
            life: 3000,
          });

          this.enseignantDialog = false;
          this.tcheacher = {};
        })
        .catch((err) => {
          this.loadDeletetcheacher = false;

          this.$toast.add({
            severity: "error",
            summary: "Attention",
            detail: "Vous ne pouvez pas supprimer cet enseignant ",
            life: 3000,
          });
          console.log(err);
        });

      this.deleteenseignantDialog = false;
      this.tcheacher = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.tcheachers.length; i++) {
        if (this.tcheachers[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deletetcheachersDialog = true;
    },
    deleteSelectedtcheachers() {
      this.tcheachers = this.tcheachers.filter(
        (val) => !this.selectedtcheachers.includes(val)
      );

      this.deletetcheachersDialog = false;
      this.selectedtcheachers = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "tcheachers Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.import {
  height: 200px;
  width: 200px;
  border: dashed;
}

.plus {
  font-size: 200px;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.tcheacher-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .tcheacher-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
