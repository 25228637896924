import { createStore } from "vuex";
import axios from "axios";
import auth from "./auth";
import classes from "./classes";
import courses from "./courses";
import planning from "./planning";
import accounting from "./accounting";
import teatcher from "./teatcher";
axios.defaults.baseURL = "https://itnovation-myschoolapi.herokuapp.com/api/";
// axios.defaults.headers.common.Authorization = `Bearer 5|PHZLduDX2UHB5N93bnxlRFBRqI76JMIx2CcH427F`;
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    classes,
    courses,
    planning,
    accounting,
    teatcher,
  },
});
