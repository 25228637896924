<template>
  <div class="students">
    <div class="row">
      <div class="col-8 dotted mx-2">
        <Entete title="Comptabilité" withoutActions />
      </div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="row my-4">
      <div class="col-12 dotted">
        <h3 class="mt-4" v-if="statsTitle">{{ statsTitle }}</h3>
        <div class="row my-4 gx-5 text-start" v-if="cardStats">
          <div class="col" v-for="(stat, index) in cardStats" :key="index">
            <div class="solid p-2">
              <h5>{{ stat.title }}</h5>
              <span class="fw-bold"> {{ stat.subTitle }} </span>
              <h5 class="">{{ stat.value }} {{ stat.currency }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="dotted">
        <slot name="dataTable"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";

export default {
  props: {
    cardStats: {
      type: Array,
      required: true,
    },
    statsTitle: {
      type: String,
      required: true,
    },
  },
  components: { User, Entete },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}
</style>
