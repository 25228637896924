<template>
  <div class="recap-stat"></div>
</template>

<style scoped>
.recap-stat {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;

  /* width: 793px; */
  height: 407px;

  background-image: url("@/assets/img/Chart.png");
  background-size: contain;
}
</style>
