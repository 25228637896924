<template>
  <div class="single-class">
    <div class="row single p-1 over" @click="singleClass">
      <div class="col-9 text-start">{{ name }}</div>
      <div class="col text-end">
        <i class="pi pi-pencil icon-pen" @click="sendId()"></i>
      </div>

      <div class="col text-start">
        <i class="pi pi-times icon-cross" @click="deleteClass()"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "id"],
  setup() {},

  methods: {
    singleClass() {
      let data = { id: this.id, libelle: this.name };
      this.$store.commit("classes/setClasse", data);
      this.$router.push({ name: "single-classe", params: { id: this.id } });
    },
    sendId() {
      let data = { id: this.id, libelle: this.name };
      this.$emit("sendId", data);
    },

    deleteClass() {
      this.$emit("deleteClass", this.id);
    },
  },
};
</script>

<style scoped>
.icon-pen {
  /* background-color: #7b61ff; */
  color: #7b61ff;
  cursor: pointer;
}
.over {
  cursor: pointer;
}
.icon-cross {
  color: red;
  cursor: pointer;
}
.single-class {
  padding: 0px 10px 0px 10px;
}
.single {
  /* green-100 */

  background: #e6fbd9;
  /* Gray/200 */

  border: 1px solid #eaecee;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
</style>
