<template>
  <div class="my-3">
    <div class="card">
      <!-- Entête de la table ------------------------ -->

      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Ajouter"
            icon="pi pi-plus"
            class="p-button-success mr-2"
            @click="openNew"
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedProducts || !selectedProducts.length"
          />
        </template>

        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="products"
        v-model:selection="selectedProducts"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        responsiveLayout="scroll"
      >
        <template #header>
          <div
            class="table-header flex flex-column md:flex-row md:justiify-content-between"
          >
            <h5 class="mb-2 md:m-0 p-as-md-center">Liste des élèves</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Search..."
              />
            </span>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          style="width: 3rem"
          :exportable="false"
        ></Column>

        <Column
          field="name"
          header="Nom et prenom"
          :sortable="true"
          style="min-width: 10rem"
        ></Column>
        <!-- <Column
          field="picture"
          header="Photo"
          :sortable="true"
          style="min-width: 16rem"
        ></Column> -->
        <Column header="Photo">
          <template #body="slotProps">
            <img
              src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
              :alt="slotProps.data.name"
              class="product-image"
            />
          </template>
        </Column>
        <Column
          field="classe"
          header="classe"
          :sortable="true"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            {{ formatCurrency(slotProps.data.classe) }}
          </template>
        </Column>
        <!-- <Column
          field="category"
          header="Category"
          :sortable="true"
          style="min-width: 10rem"
        ></Column>
        <Column
          field="rating"
          header="Reviews"
          :sortable="true"
          style="min-width: 12rem"
        >
          <template #body="slotProps">
            <Rating
              :modelValue="slotProps.data.rating"
              :readonly="true"
              :cancel="false"
            />
          </template>
        </Column> -->
        <Column
          field="status"
          header="Status paiement"
          :sortable="true"
          style="min-width: 12rem"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.paiement === 0" class="bg-danger p-2"
              >{{ slotProps.data.paiement }} sur 2</span
            >

            <span
              v-else-if="slotProps.data.paiement === 1"
              class="bg-warning p-2"
              >{{ slotProps.data.paiement }} sur 2</span
            >

            <span v-else class="bg-success p-2"
              >{{ slotProps.data.paiement }} sur 2</span
            >
          </template>
        </Column>
        <Column header="Actions" :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="editProduct(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDeleteProduct(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Modal edit product ------- And create product --------------------------------------------------------------------------- -->
    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '450px' }"
      header="Product Details"
      :modal="true"
      class="p-fluid"
    >
      <img
        src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
        :alt="product.image"
        class="product-image"
        v-if="product.image"
      />
      <div class="field">
        <label for="name">Name</label>
        <InputText
          id="name"
          v-model.trim="product.name"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.name }"
        />
        <small class="p-error" v-if="submitted && !product.name"
          >Name is required.</small
        >
      </div>
      <div class="field">
        <label for="description">Description</label>
        <Textarea
          id="description"
          v-model="product.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <div class="field">
        <!-- <label for="inventoryStatus" class="mb-3">Inventory Status</label>
        <Dropdown
          id="inventoryStatus"
          v-model="product.inventoryStatus"
          :options="statuses"
          optionLabel="label"
          placeholder="Select a Status"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' + slotProps.value.value">{{
                slotProps.value.label
              }}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span
                :class="'product-badge status-' + slotProps.value.toLowerCase()"
                >{{ slotProps.value }}</span
              >
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
        </Dropdown> -->
      </div>

      <!-- <div class="field">
        <label class="mb-3">Category</label>
        <div class="formgrid grid">
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category1"
              name="category"
              value="Accessories"
              v-model="product.category"
            />
            <label for="category1">Accessories</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category2"
              name="category"
              value="Clothing"
              v-model="product.category"
            />
            <label for="category2">Clothing</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category3"
              name="category"
              value="Electronics"
              v-model="product.category"
            />
            <label for="category3">Electronics</label>
          </div>
          <div class="field-radiobutton col-6">
            <RadioButton
              id="category4"
              name="category"
              value="Fitness"
              v-model="product.category"
            />
            <label for="category4">Fitness</label>
          </div>
        </div>
      </div> -->

      <div class="formgrid grid">
        <div class="field col">
          <label for="price">Price</label>
          <InputNumber
            id="price"
            v-model="product.price"
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div class="field col">
          <label for="quantity">Quantity</label>
          <InputNumber id="quantity" v-model="product.quantity" integeronly />
        </div>
      </div>
      <!-- <div class="image">
        label
        <div class="import">
          <span class="plus text-center"> + </span>
        </div>
        <input
          class="form-control"
          type="file"
          id="formFile"
          accept="image/*"
        />
      </div> -->
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          v-if="!loadAddAndDeleteProduct"
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProduct"
        />

        <ProgressSpinner
          v-else
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </template>
    </Dialog>

    <!-- Modal for confirmation deleted one  product ----------------------------------------------------- -->
    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product"
          >Voulez vous vraiment supprimer <b>{{ product.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          v-if="!loadDeleteProduct"
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
        <ProgressSpinner
          v-else
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </template>
    </Dialog>
    <!-- Modal for confirmation deleted Multiple product  product ----------------------------------------------------- -->
    <Dialog
      v-model:visible="deleteProductsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product"
          >Are you sure you want to delete the selected products?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductsDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedProducts"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
// import ProductService from "./service/ProductService";

import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Rating from "primevue/rating";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import students from "../../datas.json";

export default {
  components: {
    Toolbar,
    FileUpload,
    DataTable,
    InputText,
    Column,
    Rating,
    Dialog,
    Dropdown,
    RadioButton,
    Textarea,
    InputNumber,
  },
  data() {
    return {
      // products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      products: [],
      selectedProducts: null,
      filters: {},
      submitted: false,
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
      loadAddAndDeleteProduct: false,
      loadDeleteProduct: false,

      data: students,
    };
  },

  computed: {},
  created() {
    // this.productService = new ProductService();
    this.initFilters();
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      let classes = ["6 ème", "5 ème", "4ème", "3 ème"];
      this.data.map((item) => {
        let id = Math.floor(Math.random() * (3 - 0) + 0);
        let obj = {
          id: item.id,
          name: item.name,
          classe: classes[id],
          paiement: Math.floor(Math.random() * (3 - 0) + 0),
        };

        this.products.push(obj);
      });
    },

    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    //method to save and update product
    saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
        this.loadAddAndDeleteProduct = true;
        if (this.product.id) {
          let dataToUpdate = {
            nom: this.product.name,
            nombre: this.product.quantity,
            prix: this.product.price,
            description: this.product.description,
            id_product: this.product.id,
          };

          this.$store
            .dispatch("product/updateProduct", dataToUpdate)
            .then((res) => {
              this.loadAddAndDeleteProduct = false;
              console.log(res);
              this.getProducts();
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "Product Updated",
                life: 3000,
              });
              this.loadAddAndDeleteProduct = false;
              this.productDialog = false;
              this.product = {};
            })
            .catch((err) => {
              this.loadAddAndDeleteProduct = false;
              console.log(err);
            });
          // this.product.inventoryStatus = this.product.inventoryStatus.value
          //   ? this.product.inventoryStatus.value
          //   : this.product.inventoryStatus;
          // this.products[this.findIndexById(this.product.id)] = this.product;
        } else {
          let newProduct = {
            nom: this.product.name,
            nombre: this.product.quantity,
            prix: this.product.price,
            description: this.product.description,
          };
          //   this.$store.commit("auth/setBearer");
          this.$store
            .dispatch("product/addProduct", newProduct)
            .then((res) => {
              console.log(res);
              this.getProducts();
              this.$toast.add({
                severity: "success",
                summary: "Succes",
                detail: "Produit créé avec success Created",
                life: 3000,
              });
              this.loadAddAndDeleteProduct = false;
              this.productDialog = false;
              this.product = {};
            })
            .catch((err) => {
              this.loadAddAndDeleteProduct = false;
              console.log(err);
            });

          //   this.product.id = this.createId();
          //   this.product.code = this.createId();
          //   this.product.image = "product-placeholder.svg";
          //   this.product.inventoryStatus = this.product.inventoryStatus
          //     ? this.product.inventoryStatus.value
          //     : "INSTOCK";
          //   this.products.unshift(this.product);
        }
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },

    //method to handle modal to confirm delete product
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },

    //method for delete signgle product product
    deleteProduct() {
      // this.products = this.products.filter((val) => val.id !== this.product.id);
      this.loadDeleteProduct = true;

      this.$store
        .dispatch("product/deleteProduct", this.product.id)
        .then((res) => {
          console.log(res);
          this.getProducts();
          this.loadDeleteProduct = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Deleted",
            life: 3000,
          });

          this.productDialog = false;
          this.product = {};
        })
        .catch((err) => {
          this.loadDeleteProduct = false;
          console.log(err);
        });

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.import {
  height: 200px;
  width: 200px;
  border: dashed;
}

.plus {
  font-size: 200px;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
