<template>
  <div class="classe p-4">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="row dotted p-3">
          <div class="col-12 p-4 solid fw-bold">{{ classeInfos.libelle }}</div>
        </div>
        <!-- <div class="row dotted my-2 p-3">
          <div v-if="loaderClasses">
            <Skeleton
              v-for="i in 10"
              :key="i"
              width="100%"
              class="mb-2"
            ></Skeleton>
          </div>
        </div> -->
      </div>

      <div class="col">
        <div class="row dotted mx-2 p-4">
          <div class="solid row p-2">
            <div class="col p-0 text-start">
              <button class="btn-color" @click="showModal">
                Ajouter une matiere
              </button>
            </div>
            <div class="col text-end">
              <button class="btn-color" @click="pushTime">
                Emplois de temps
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row dotted mt-2">
      <div>
        <DataTable
          :value="classeCourse"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="5"
        >
          <template #header>
            <div class="table-header">
              Liste des Matières
              <span class="p-input-icon-right">
                <InputText
                  placeholder="Rechercher"
                  type="text"
                  v-model="value2"
                />
                <i class="pi pi-search" />
              </span>
            </div>
          </template>
          <Column field="libelle" header="Intitulé"></Column>
          <Column header="Enseignant">
            <template #body="slotProps">
              <img
                src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                :alt="slotProps.data.enseignant"
                class="product-image"
              />
            </template>
          </Column>
          <Column field="nombre_heure" header="Planning horaire">
            <template #body="slotProps">
              <strong>{{ slotProps.data.nombre_heure }}</strong>
            </template>
          </Column>
          <Column field="status" header="Status">
            <template #body="slotProps">
              <Badge :value="slotProps.data.status"></Badge>
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <span
                :class="
                  'product-badge status-' + slotProps.data.inventoryStatus
                "
              >
                <i class="pi pi-ellipsis-h over"></i>
              </span>
            </template>
          </Column>
          <template #footer>
            au total il y'a {{ courses ? courses.length : 0 }} Matières.
          </template>
        </DataTable>
      </div>
    </div>

    <Dialog
      header="Ajouter une période"
      v-model:visible="display"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
    >
      <!-- <div class="grid p-fluid"> -->

      <!-- </div> -->

      <div class="row mt-2">
        <div class="col"><small>Matière</small></div>
        <div class="col-9">
          <Dropdown
            class="w-100"
            id="dropdown"
            v-model="course"
            :options="courses"
            optionLabel="libelle"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><small>Nombre d'heure</small></div>
        <div class="col-9">
          <InputNumber class="w-100" v-model="number" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><small>Enseignant</small></div>
        <div class="col-9">
          <Dropdown
            class="w-100"
            id="dropdown"
            v-model="teatcher"
            :options="tcheatchers"
            optionLabel="first_name"
          />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col"><small>Prix Enseignant</small></div>
        <div class="col-9">
          <InputNumber class="w-100" v-model="price" />
        </div>
      </div>

      <div class="row">
        <div class="col text-end">
          <Button
            :loading="isLoading"
            label="Ajouter"
            class="p-button-raised p-button-text mt-4"
            @click="addCourse"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { notification } from "@/utils/Util";
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Rating from "primevue/rating";
import Badge from "primevue/badge";
import InputNumber from "primevue/inputnumber";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

export default {
  components: {
    User,
    Entete,
    DataTable,
    Column,
    Rating,
    InputText,
    Badge,
    Dialog,
    Dropdown,
    Button,
    InputNumber,
  },
  data() {
    return {
      display: false,
      isLoading: false,
      course: "",
      teatcher: "",
      price: "",
      number: "",
      // teatchers: [
      //   { id: 5, first_name: "marco" },
      //   { id: 6, first_name: "Lele" },
      // ],
      // courses: [
      //   {
      //     libelle: "Maths",
      //     enseignant: "enseignant",
      //     nombre_heure: "12",
      //     status: "en cours",
      //   },
      //   {
      //     libelle: "Infos",
      //     enseignant: "enseignant",
      //     nombre_heure: "10",
      //     status: "en cours",
      //   },
      // ],
    };
  },

  computed: {
    tcheatchers() {
      return this.$store.state.auth.tcheatchers;
    },
    classeCourse() {
      return this.$store.state.classes.classeCourse;
    },
    courses() {
      return this.$store.state.courses.Courses;
    },
    classeInfos() {
      return this.$store.state.classes.classe;
    },
  },

  methods: {
    showModal() {
      this.display = true;

      this.$store.dispatch("auth/getAllTcheatcher");
    },
    pushTime() {
      this.$router.push({
        name: "time-table",
        params: { id: this.$route.params.id },
      });
    },

    addCourse() {
      if (
        this.course.id === "" ||
        this.number === "" ||
        this.teatcher.id === "" ||
        this.price === ""
      ) {
        this.$toast.add({
          severity: "warn",
          summary: "Warning ",
          detail: "Veuillez remplir tous les champs ",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        let data = {
          id: this.$route.params.id,
          id_matiere: this.course.id,
          nombre_heure: this.number,
          enseignant_id: this.teatcher.id,
          prix_enseignant: this.price,
        };

        this.$store
          .dispatch("classes/addCourseToClasse", data)
          .then((res) => {
            this.getClasseCourses();
            this.isLoading = false;
            this.display = false;
            this.$toast.add({
              severity: "success",
              summary: "Success Message",
              detail: "Matière ajouté avec success",
              life: 3000,
            });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "error",
              summary: "Error Message",
              detail: "une erreur est survenu",
              life: 3000,
            });
          });
        console.log("data to send ", data);
      }
    },

    getClasseCourses() {
      this.$store
        .dispatch("classes/getClasseCourse", this.$route.params.id)
        .then((res) => {
          console.log("res  ", res);
        });
    },

    initialise() {
      this.$store.dispatch("courses/allCourses").then((res) => {
        console.log("res  ", res);
      });

      this.$store
        .dispatch("classes/getOneClasse", this.$route.params.id)
        .then((res) => {
          console.log("res  ", res);
        });

      this.getClasseCourses();
    },
  },

  mounted() {
    this.initialise();
  },
};
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

select {
  background-color: #f9f9f9;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  /* width: 70%; */
  padding: 6px;
}

.input-group-text {
  background-color: white;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
}

.input {
  background-color: #e7e2e2;
}
</style>
