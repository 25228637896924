<template>
  <div class="hour">
    <div class="time">7h30-8h30</div>
  </div>
</template>

<style scoped>
.time {
  background-color: #7b61ff;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}
</style>
