<template>
  <div class="course">
    {{ name }} <i class="pi pi-th-large mx-2"></i>

    <Dialog header="Header" v-model:visible="display">
      Content | {{ name }}
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
export default {
  components: { Dialog },
  props: ["name", "status", "id", "index"],
  setup() {},

  computed: {
    id() {
      let id = this.$store.state.classes.instanceModal.id;
      console.log("id-- ", this.$store.state.classes.instanceModal.id);
      return this.$store.state.classes.instanceModal.id;
    },
    display() {
      let id = this.$store.state.classes.instanceModal.id;
      let index = this.$store.state.classes.instanceModal.index;
      console.log("composant course item  id", id, "index ", index);
      if (id === this.id && index === this.index) {
        return true;
      } else return false;
    },
  },
};
</script>

<style scoped>
.course {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;
}
</style>
