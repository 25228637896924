import axios from "axios";

export default {
  namespaced: true,

  state: {
    classes: [],
    classesFilter: [],
    classe: {},
    classeCourse: [],
    instanceModal: { id: "", index: "" },
  },
  getters: {
    getAllClasses: (state) => {
      return state.classes;
    },

    getfilterClasses: (state) => {
      return state.classesFilter;
    },
  },
  mutations: {
    setInstanceModal(state, data) {
      console.log("store action ", data);
      state.instanceModal = data;
    },

    setOneClasses(state, data) {
      state.classes.unshift(data);
    },

    setClasses(state, data) {
      state.classes = data;
    },

    setUpdateClasses(state, data) {
      state.classes.forEach((element, index) => {
        if (element.id === data.id) state.classes[index] = data.data;
      });
    },

    setDeleteClasses(state, id) {
      state.classes = state.classes.filter((item) => {
        return item.id !== id;
      });
    },

    setSearch(state, key) {
      console.log(key);
      state.classesFilter = state.classes.filter((item) => {
        return item.libelle.includes(key);
      });
    },

    setClasse(state, data) {
      state.classe = data;
    },

    setClasseCourse(state, data) {
      state.classeCourse = [];
      data.forEach((item) => {
        let obj = {
          id: item.matiere.id,
          libelle: item.matiere.libelle,
          nombre_heure: item.nombre_heure,
          prix_enseignant: item.prix_enseignant,
        };
        state.classeCourse.push(obj);
      });

      console.log("setters  ", state.classeCourse);
    },

    setOneClasse(state, data) {
      state.classe = data;
    },
  },
  actions: {
    createClasse({ commit }, data) {
      return axios.post("classes", data).then((res) => {
        commit("setOneClasses", res.data);
        return res;
      });
    },

    allClasses({ commit }, data) {
      return axios.get("classes").then((res) => {
        commit("setClasses", res.data);
        return res;
      });
    },

    updateClasse({ commit }, data) {
      return axios.put(`classes/${data.id}`, data.data).then((res) => {
        let infos = { data: res.data, id: data.id };
        commit("setUpdateClasses", infos);
        return res;
      });
    },

    getOneClasse({ commit }, id) {
      return axios.get(`classes/${id}`).then((res) => {
        commit("setOneClasse", res.data);
        return res;
      });
    },
    deleteClasse({ commit }, id) {
      return axios.delete(`classes/${id}`).then((res) => {
        commit("setDeleteClasses", id);
        return res;
      });
    },
    getClasseCourse({ commit }, id) {
      return axios.get(`classes/${id}/matieres`).then((res) => {
        console.log("res get classe course", res.data);
        commit("setClasseCourse", res.data);
        return res;
      });
    },
    addCourseToClasse({ commit }, data) {
      return axios.post(`classes/${data.id}/matieres`, data).then((res) => {
        console.log("res get classe course");

        return res;
      });
    },
  },
};
