<template>
  <div class="filter">
    <form>
      <h4>Filtres</h4>
      <select class="form-select" aria-label="Disabled select example">
        <option v-for="(item, id) in years" :key="id">
          {{ item.value }}
        </option>
      </select>
      <select class="form-select my-4" aria-label="Disabled select example">
        <option v-for="(item, id) in classes" :key="id">
          {{ item.value }}
        </option>
      </select>

      <select class="form-select my-4" aria-label="Disabled select example">
        <option v-for="(item, id) in students" :key="id">
          {{ item.value }}
        </option>
      </select>

      <button type="button" class="btn btn-primary my-5 form-control">
        Appliquer
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      years: [
        { id: 1, value: "Année" },
        { id: 2, status: true, value: 2022 },
        { id: 3, status: false, value: 2021 },
        { id: 4, status: false, value: 2020 },
        { id: 5, status: false, value: 2019 },
      ],

      classes: [
        { id: 1, value: "Classes" },
        { id: 2, status: true, value: " 6 eme" },
        { id: 3, status: false, value: "5 ème" },
        { id: 4, status: false, value: "4 ème" },
        { id: 5, status: false, value: "3 ème" },
      ],

      students: [
        { id: 1, value: "Classes" },
        { id: 2, status: true, value: "student 1" },
        { id: 3, status: false, value: "student 2" },
        { id: 4, status: false, value: "student 3" },
        { id: 5, status: false, value: " student 4" },
      ],
    };
  },
};
</script>

<style scoped>
.filter {
  /* width: 301px; */
  height: 400px;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
  padding: 20px;
}

form {
  background: #ffffff;
  border: 1px solid #eaecee;
  border-radius: 12px;
  padding: 20px;
  height: 100%;
}

select {
  background-color: #f9f9f9;
}

.btn-primary {
  background: #5e00bc;
}
</style>
