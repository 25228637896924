<template>
  <div class="time-table">
    <table class="mx-3 my-3">
      <thead>
        <tr>
          <th scope="col"><p class="day p-1">Heures</p></th>

          <th scope="col " v-for="(item, id) in datas[0]" :key="id">
            <p class="day p-1" v-if="id !== 0">{{ item }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item1, id) in datas" :key="id">
          <th scope="row">
            <p class="day p-1" v-if="id !== 0">{{ item1[0] }}</p>
          </th>
          <td v-for="(elt, index) in item1" :key="index">
            <div class="plan fw-bold">
              <p v-if="index !== 0 && id !== 0" class="solid1">
                {{ elt.matiere }}
                <i
                  class="pi pi-th-large mx-2 over"
                  @click="actions(id, index, elt, item1)"
                ></i>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Dialog header="Ajouter une période" v-model:visible="display">
      <!-- <div class="grid p-fluid"> -->
      <div class="row">
        <div class="col p-0">Jour</div>

        <div class="col-9">
          <!-- <div class="col-12 md:col-4"> -->
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"> {{ day }} </span>
            <Calendar
              v-model="date"
              view="date"
              :showIcon="true"
              dateFormat=""
              @date-select="checkDate"
            />
            <!-- </div> -->
          </div>
        </div>
      </div>

      <!-- </div> -->

      <div class="row mt-2">
        <div class="col p-0"><small>Matière</small></div>
        <div class="col-9">
          <Dropdown
            class="w-100"
            id="dropdown"
            v-model="course"
            :options="courses"
            optionLabel="libelle"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col p-0"><small>heure de debut</small></div>
        <div class="col-9">
          <Calendar
            class="w-100"
            v-model="h_debut"
            :timeOnly="true"
            :showTime="true"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col p-0"><small>heure de fin</small></div>
        <div class="col-9">
          <Calendar
            class="w-100"
            v-model="h_fin"
            :timeOnly="true"
            :showTime="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col text-end">
          <Button
            label="Ajouter"
            class="p-button-raised p-button-text mt-4"
            :loading="isLoading"
            @click="handleAdd"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";

export default {
  components: { Dialog, InputText, Calendar, Dropdown },
  data() {
    return {
      display: false,
      value: null,
      day: "",
      date: null,
      h_debut: null,
      h_fin: null,
      course: "",

      // courses: [
      //   { name: "Maths", id: "1" },
      //   { name: "Physique", id: "2" },
      //   { name: "Chimie", id: "3" },
      // ],
      isLoading: false,
    };
  },

  computed: {
    courses() {
      return this.$store.state.classes.classeCourse;
    },
    datas() {
      return this.$store.state.planning.plannings;
    },
  },
  methods: {
    actions(id, index, dataTarget, dataRow) {
      this.$store.dispatch("classes/getClasseCourse", this.$route.params.id);
      this.day = this.datas[0][index];
      this.display = true;

      this.h_debut = dataTarget.h_debut;
      this.h_fin = dataTarget.h_fin;
      this.id = dataTarget.id;
      this.jour = dataTarget.jour;
      this.course = dataTarget.id_matiere;
      //   this.$store.commit("classes/setInstanceModal", { id: id, index: index });
      console.log(
        "id ",
        id,
        "index ",
        index,
        "data ",
        dataTarget,
        "datas ",
        dataRow,
        "day -- ",
        this.datas[0][index]
      );
    },

    checkDate(e) {
      console.log(e);
      let dates = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      let day = e.getDay();
      console.log("id ", day, " day ", dates[day]);
      if (!(this.day === dates[day]))
        this.$toast.add({
          severity: "error",
          summary: "Error Message",
          detail:
            "cette date ne correspond pas à un " +
            this.day +
            " veuillez choisir une autre date",
          life: 4000,
        });
    },

    handleAdd() {
      let d = this.date.toLocaleDateString("fr").split("/");
      let h1 = this.h_debut.toLocaleTimeString("fr").substring(0, 5);
      let h2 = this.h_fin.toLocaleTimeString("fr").substring(0, 5);

      let data = {
        // jour: `${this.date.getFullYear()}-${
        //   this.date.getMonth() + 1//
        // }-${this.date.getDate()}`,

        jour: `${d[2]}-${d[1]}-${d[0]}`,
        id_course: this.course.id,
        h_debut: h1,
        h_fin: h2,
        class_id: this.$route.params.id,
      };
      console.log(data);

      this.isLoading = true;
      this.$store
        .dispatch("planning/addCoursePeriod", data)
        .then((res) => {
          this.init();
          this.isLoading = false;
          this.display = false;

          this.$toast.add({
            severity: "success",
            summary: "success ",
            detail: "Horaire Ajouté avec success ",
            life: 3000,
          });
        })
        .catch((err) => {
          console.log("è((( ", err);
          this.isLoading = false;

          this.$toast.add({
            severity: "error",
            summary: "erreur ",
            detail: err.response.data.error,
            life: 3000,
          });
        });
    },

    init() {
      this.$store.dispatch("planning/allPlanning", this.$route.params.id);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.day {
  background-color: #7b61ff;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.solid1 {
  box-sizing: border-box;

  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}
.over {
  cursor: pointer;
}

.plan {
  /* display: flex; */
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
