<template>
  <div class="row">
    <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

    <div class="col dotted"><User /></div>
  </div>

  <div class="row mt-4 dotted">
    <TeatcherTable />
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import TeatcherTable from "@/components/dashboard/TeatcherTable.vue";
export default {
  components: { TeatcherTable, User, Entete },

  data() {
    return {
      entete_title: "Enseignants  ",
    };
  },
};
</script>
