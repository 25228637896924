<template>
  <AccountingLayout :cardStats="cardStats" :statsTitle="statsTitle">
    <template #dataTable>
      <AccountingDataTable :data="accountsData" :columns="columns" />
    </template>
  </AccountingLayout>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import AccountingLayout from "@/components/AccountingLayout.vue";
import AccountingDataTable from "@/components/AccountingDataTable.vue";

export default {
  components: { User, Entete, AccountingDataTable, AccountingLayout },
  data() {
    return {
      statsTitle: "Recap 2022",
      accountsData: [],
      columns: [],
    };
  },
  computed: {
    accounts() {
      return this.$store.getters["accounting/getAllAccounts"];
    },

    cardStats() {
      return this.$store.state.accounting.cardStats;
    },
  },
  methods: {
    getClassCost(coursesList) {
      let cost = 0;
      coursesList.forEach((elt) => {
        cost = cost + elt.prix_enseignant * elt.nombre_heure;
      });

      return cost;
    },

    async initColumsData() {
      this.columns = [
        {
          field: "class",
          header: "Classe",
          sortable: true,
        },
        {
          field: "cost",
          header: "Coût mensuel en Fcfa",
          sortable: true,
        },
        {
          field: "courses",
          header: "Matières",
          sortable: true,
        },
        {
          field: "goTo",
          header: "Consulter",
          sortable: false,
        },
      ];
    },
  },
  async mounted() {
    // this.cardStats = [
    //   {
    //     title: "classe la plus couteuse",
    //     subTitle: "Tle D",
    //     value: 1200000,
    //     currency: "Fcfa",
    //   },
    //   {
    //     title: "classe la moins couteuse",
    //     subTitle: "6ème M1",
    //     value: 700000,
    //     currency: "Fcfa",
    //   },
    //   {
    //     title: "classe la plus couteuse",
    //     subTitle: "6ème M1",
    //     value: 200000,
    //     currency: "Fcfa",
    //   },
    // ];
    await this.$store
      .dispatch("accounting/getGeneralAccountingDetails")
      .then((res) => {
        res.map((account) => {
          const cost = this.getClassCost(account.matieres);
          let obj = {
            id: account.classe.id,
            class: account.classe.libelle,
            cost,
            courses: account.matieres.length,
          };

          this.accountsData.push(obj);
        });
        this.initColumsData();
      });
  },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}
</style>
