import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Student from "@/views/Student.vue";
import Appel from "@/views/Appel.vue";
import Classe from "@/views/Classe.vue";
import Course from "@/views/Course.vue";
import Parent from "@/views/Parent.vue";
import Tcheacher from "@/views/Tcheacher.vue";
import Accounting from "@/views/Accounting.vue";
import ClassAccounting from "@/views/ClassAccounting.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import SingleClass from "@/views/SingleClass.vue";
import TimeTable from "@/views/TimeTable.vue";
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    children: [
      // {
      //   path: "",

      //   redirect: "home",
      //   // component: Home,
      // },

      {
        path: "home",
        name: "home",

        component: Home,
      },
      {
        path: "student",
        name: "student",
        component: Student,
      },
      {
        path: "appel",
        name: "appel",
        component: Appel,
      },
      {
        path: "class",
        name: "class",
        component: Classe,
      },
      {
        path: "course",
        name: "course",
        component: Course,
      },
      {
        path: "parent",
        name: "parent",
        component: Parent,
      },
      {
        path: "tcheacher",
        name: "tcheacher",
        component: Tcheacher,
      },
      {
        path: "accounting",
        name: "accounting",
        component: Accounting,
      },
      {
        path: "accounting/:id",
        name: "classAccounting",
        component: ClassAccounting,
      },
      {
        path: "class/:id",
        name: "single-classe",
        component: SingleClass,
      },
      {
        path: "class/:id/time-table",
        name: "time-table",
        component: TimeTable,
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },

  // {
  //   path: "/about",
  //   name: "about",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
