<template>
  <div class="parents">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="row mt-4 dotted">
      <ParentTable />
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import ParentTable from "@/components/dashboard/ParentTable.vue";
export default {
  components: { User, Entete, ParentTable },

  data() {
    return {
      entete_title: "Parents d'élèves ",
    };
  },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

select {
  background-color: #f9f9f9;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  width: 70%;
  padding: 3px;
}
</style>
