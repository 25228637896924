<template>
  <div class="entete my-2">
    <div class="row p-2">
      <div class="col-12 col-md">
        <div class="row">
          <div class="col-6 text-start fw-bold">
            <h4>{{ title }}</h4>
          </div>

          <div class="row text-start mt-3" v-if="!withoutActions">
            <div class="col-2 acive over">
              <i class="fa-solid fa-list-check"></i> Liste
            </div>
            <div class="col-3 over">
              <i class="fa-solid fa-square-plus mx-1"></i>Ajouter
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    withoutActions: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style>
.entete {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

.acive {
  color: #5e00bc;
}

.over {
  cursor: pointer;
  transition: 0.3s;
}

.over:hover {
  color: #5e00bc;
  transform: scale(1.05);
}
</style>
