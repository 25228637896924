<template>
  <div class="students">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="mt-4 row">
      <div class="col-12 dotted">
        <div class="solid row m-2">
          <div class="col-12 text-start">Filtres</div>
          <div class="col text-start" @click="test">
            classes

            <select class="form-select" aria-label="Disabled select example">
              <option v-for="(item, id) in classes" :key="id">
                {{ item.value }}
              </option>
            </select>
          </div>

          <div class="col text-start">
            paiement
            <select class="form-select" aria-label="Disabled select example">
              <option v-for="(item, id) in tranches" :key="id">
                {{ item.value }}
              </option>
            </select>
          </div>

          <div class="col">
            <br />
            <button class="btn-color">appliquer</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 dotted">
      <StudentTable />
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import StudentTable from "@/components/dashboard/StudentTable.vue";
export default {
  components: { User, Entete, StudentTable },
  data() {
    return {
      entete_title: "ELEVES",

      classes: [
        { id: 1, value: "Classes" },
        { id: 2, status: true, value: " 6 eme" },
        { id: 3, status: false, value: "5 ème" },
        { id: 4, status: false, value: "4 ème" },
        { id: 5, status: false, value: "3 ème" },
      ],

      tranches: [
        { id: 1, value: "1 ère tranche" },
        { id: 2, value: " 2 ème tranche" },
        { id: 3, value: "3 ème tranche" },
      ],
    };
  },

  methods: {
    test() {
      this.$store.dispatch("auth/login", { data: "yoo man" });
    },
  },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

select {
  background-color: #f9f9f9;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  width: 70%;
  padding: 3px;
}
</style>
