<template>
  <Toast />
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      appClass: "col-lg-12 col-lg-offset-2",
      open: false,
    };
  },

  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.$store.commit("auth/setUser", user);
      this.$router.push({ name: "home" });
      ("");
    } else this.$router.push({ name: "login" });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #000000 !important;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* style for side bar */
body {
  position: relative;
  overflow-x: hidden;
  background-color: #fff;
}
body,
html {
  height: 100%;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/
</style>
