<template>
  <div class="row">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="my-4 row">
      <div class="col-8">
        <div class="dotted mt-2">
          <div
            class="solid m-2 d-flex flex-row justify-content-between align-items-center px-4 py-2"
          >
            <div class="text-start">Liste des matières</div>
            <div class="">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />

                <InputText placeholder="Rechercher..." />
              </span>
            </div>
          </div>
        </div>

        <div class="mt-4 dotted px-4 py-2">
          <span v-for="item in allCourses" :key="item.id">
            <Elt
              :label="item.libelle"
              :id="item.id"
              @edit-elt="handleEdit"
              @delete-elt="handleDelete"
            />
          </span>
        </div>
      </div>

      <div class="col-4 h-100 dotted p-4">
        <Heading title="Ajouter une matière" />

        <div class="mt-4">
          <form @submit="addCourse">
            <InputText
              v-model="name"
              class="p-inputtext-sm w-100"
              placeholder="libellé de la matière"
            />
            <div v-if="loaderAdd" class="spinner-grow mt-4" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <Button
              v-else
              type="submit"
              label="Enregistrer"
              class="w-100 mt-4"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import InputText from "primevue/inputtext";
import Elt from "@/components/AppDataTableElt.vue";
import Heading from "@/components/AppTitleBanner.vue";
import Button from "primevue/button";

export default {
  components: { User, Entete, InputText, Elt, Heading, Button },
  data() {
    return {
      entete_title: "MATIERES",
      name: "",
      search: "",
      loaderAdd: false,
    };
  },
  methods: {
    handleEdit(data) {
      let datas = { id: data.id, libelle: data.infos };
      this.$store
        .dispatch("courses/updateCourse", datas)
        .then((res) => {})
        .catch((err) => {});
    },
    handleDelete(id) {
      this.$store
        .dispatch("courses/deleteCourse", id)
        .then((res) => {})
        .catch((err) => {});
    },

    addCourse(e) {
      e.preventDefault();
      this.loaderAdd = true;
      this.$store
        .dispatch("courses/createCourse", { libelle: this.name })
        .then((res) => {
          this.loaderAdd = false;
        })
        .catch((err) => {
          this.loaderAdd = false;
        });
    },

    getAllCourses() {
      this.$store.dispatch("courses/allCourses");
    },
  },

  mounted() {
    this.getAllCourses();
  },

  computed: {
    allCourses() {
      return this.search === ""
        ? this.$store.getters["courses/getAllCourses"]
        : this.$store.getters["courses/getfilterCourses"];
    },
  },
};
</script>
<style scoped>
.dotted {
  box-sizing: border-box;

  /* Auto layout */

  /* display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 24px; */

  /* position: absolute;
  width: 793px;
  height: 197px;
  left: 322px;
  top: 23px; */

  /* Base/background white */

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}
.solid {
  box-sizing: border-box;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

.box {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}
</style>
