<template>
  <div class="content">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="entete1 dotted"><HeaderLeft /></div>
      </div>
      <div class="col col-md-4">
        <div class="entete2 dotted"><HeaderRigth /></div>
      </div>
    </div>

    <div class="row my-4">
      <div class="col-12 col-md-8">
        <RecapStat />
      </div>

      <div class="col-12 col-md-4">
        <Filter />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLeft from "../components/dashboard/contentElements/HeaderLeft.vue";
import HeaderRigth from "../components/dashboard/contentElements/Profil.vue";
import RecapStat from "../components/dashboard/contentElements/RecapStat.vue";
import Filter from "../components/dashboard/contentElements/Filter.vue";
export default {
  components: { HeaderLeft, HeaderRigth, RecapStat, Filter },
  setup() {},
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  /* Auto layout */

  /* display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 24px; */

  /* position: absolute;
  width: 793px;
  height: 197px;
  left: 322px;
  top: 23px; */

  /* Base/background white */

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}
</style>
