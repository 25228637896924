import axios from "axios";

export default {
  namespaced: true,

  state: {
    user: null,
    tcheatchers: [],
  },
  getters: {},
  mutations: {
    setUser(state, data) {
      localStorage.setItem("user", JSON.stringify(data));
      console.log(data);
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      state.user = data;
    },

    setTeatcher(state, data) {
      state.tcheatchers = data;
    },
  },
  actions: {
    login({ commit }, data) {
      return axios.post("login", data).then((res) => {
        commit("setUser", res.data);
        return res;
      });
    },

    getAllTcheatcher({ commit }) {
      return axios.get("user/enseignants").then((res) => {
        console.log(res);
        commit("setTeatcher", res.data);
        return res;
      });
    },
  },
};
