<template>
  <div class="classe p-4">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="row dotted p-3">
          <div class="col-5 fw-bold">Liste des classes</div>

          <div class="col">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="entrez le nom d'une classe"
                v-model="search"
                aria-label="Dollar amount (with dot and two decimal places)"
              />
              <span class="input-group-text"><i class="pi pi-search"></i></span>
            </div>
          </div>
        </div>
        <div class="row dotted my-2 p-3">
          <div v-if="loaderClasses">
            <Skeleton
              v-for="i in 10"
              :key="i"
              width="100%"
              class="mb-2"
            ></Skeleton>
          </div>

          <span v-else class="my-2" v-for="item in allClasses" :key="item.id">
            <SingleClass
              :name="item.libelle"
              :id="item.id"
              @sendId="receiveId"
              @deleteClass="deleteClass"
            />
          </span>
        </div>
      </div>

      <div class="col">
        <div class="row dotted mx-2 p-4">
          <h4 class="solid p-4">Ajouter une classe</h4>
          <span class="solid p-4">
            <input
              type="text"
              class="form-control input"
              v-model="classe"
              placeholder="intitulé de la salle"
              aria-label="Dollar amount (with dot and two decimal places)"
            />

            <div v-if="loaderCreate" class="spinner-grow mt-4" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <button v-else class="btn-color" @click="createClasse">
              Enregistrer
            </button>
          </span>
        </div>

        <div class="row dotted mx-2 my-2 p-2">
          <h4 class="solid p-4">Modifier</h4>
          <span class="solid p-4">
            <input
              type="text"
              class="form-control input"
              v-model="classeUpdate"
              placeholder="3 ème m1"
              aria-label="Dollar amount (with dot and two decimal places)"
            />

            <div v-if="loaderUpdate" class="spinner-grow mt-4" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <button v-else class="btn-color" @click="updateClasse">
              Modifier
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import InputText from "primevue/inputtext";
import Skeleton from "primevue/skeleton";
import SingleClass from "../components/dashboard/classe/SingleClass.vue";
export default {
  components: { User, Entete, InputText, SingleClass, Skeleton },

  data() {
    return {
      entete_title: "Classes ",
      classe: "",
      classeUpdate: "",
      id: "",
      search: "",
      loaderClasses: false,
      loaderUpdate: false,
      loaderCreate: false,
    };
  },

  computed: {
    allClasses() {
      return this.search === ""
        ? this.$store.getters["classes/getAllClasses"]
        : this.$store.getters["classes/getfilterClasses"];
    },
  },

  watch: {
    search(newKey, oldKey) {
      this.handleChange(newKey);
    },
  },
  methods: {
    getAllClasses() {
      this.loaderClasses = true;
      this.$store
        .dispatch("classes/allClasses")
        .then((res) => {
          this.loaderClasses = false;

          if (res.status === 401) this.$router.push({ name: "login" });
        })
        .catch((err) => {
          this.loaderClasses = false;
        });
    },

    createClasse() {
      let data = { libelle: this.classe };
      this.loaderCreate = true;
      this.$store
        .dispatch("classes/createClasse", data)
        .then((res) => {
          this.loaderCreate = false;
        })
        .catch((err) => {
          this.loaderCreate = false;
        });
    },

    receiveId(data) {
      this.id = data.id;
      this.classeUpdate = data.libelle;
    },

    updateClasse() {
      let data = { data: { libelle: this.classeUpdate }, id: this.id };
      this.loaderUpdate = true;
      this.$store
        .dispatch("classes/updateClasse", data)
        .then((res) => {
          this.loaderUpdate = false;
        })
        .catch((err) => {
          this.loaderUpdate = false;
        });
    },

    deleteClass(id) {
      this.$store.dispatch("classes/deleteClasse", id);
    },

    handleChange(key) {
      this.$store.commit("classes/setSearch", key);
      // this.$store.dispatch("classes/search", key);
    },
  },

  mounted() {
    this.getAllClasses();
  },
};
</script>

<style scoped>
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

select {
  background-color: #f9f9f9;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  width: 70%;
  padding: 3px;
}

.input-group-text {
  background-color: white;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  width: 70%;
  padding: 3px;
  margin-left: 30%;
  margin-top: 5%;
}

.input {
  background-color: #e7e2e2;
}
</style>
