import axios from "axios";

export default {
  namespaced: true,

  state: {
    teatcher: [],
  },
  getters: {
    getAll: (state) => {
      return state.teatcher;
    },
  },
  mutations: {
    setTeatcher(state, data) {
      state.teatcher = data;
    },
    setNewTeatcher(state, data) {
      state.teatcher.unshift(data);
    },
    setUpdate(state, data) {
      let tempo = state.teatcher;

      state.teatcher = tempo.map((item) => {
        if (item.id === data.id) {
          return data;
        } else return item;
      });
    },
    setDelete(state, data) {
      state.teatcher.filter((item) => item.id !== data.id);
    },
  },
  actions: {
    createTeatcher({ commit }, data) {
      console.log(data);
      return axios.post("admin/register", data).then((res) => {
        commit("setNewTeatcher", res.data);
        return res;
      });
    },

    updateTeatcher({ commit }, data) {
      return axios.put("account/update", data).then((res) => {
        console.log("update res ", res.data);
        commit("setUpdate", res.data);
        return res;
      });
    },

    getAll({ commit }) {
      return axios.get("user/enseignants").then((res) => {
        console.log("res all teatcher res ", res.data);
        commit("setTeatcher", res.data);
        return res;
      });
    },

    deleteTeatcher({ commit }, id) {
      return axios.delete(`admin/user/${id}/delete`).then((res) => {
        console.log("res all teatcher res ", res.data);
        commit("setDelete", res.data);
        return res;
      });
    },
  },
};
