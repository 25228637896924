<template>
  <div
    @mouseenter="() => (isHover = true)"
    @mouseleave="() => (isHover = false)"
    class="course d-flex flex-row justify-content-between align-items-center rounded-3 p-2"
  >
    <div class="">{{ label }}</div>
    <div v-if="isHover">
      <Button
        icon="pi pi-pencil "
        class="mr-2 p-button-sm"
        @click="emitEditElt"
      />
      <ConfirmPopup></ConfirmPopup>

      <Button
        icon="pi pi-trash"
        class="p-button-sm p-button-danger"
        @click="handleDelete"
      />
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import ConfirmPopup from "primevue/confirmpopup";

export default {
  components: [Button, ConfirmPopup],
  props: {
    label: { type: String, required: true },
    id: { type: Number, required: true },
  },

  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    emitEditElt() {
      let data = { id: this.id, infos: this.label };
      return this.$emit("editElt", data);
    },
    handleDelete(event) {
      this.$emit("deleteElt", this.id);

      this.$confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
};
</script>

<style scoped>
.course {
  background-color: #f8f9fa;
  transition: 0.3s;
  height: 3rem;
}

.course:hover {
  background-color: #e6fbd9;
}
</style>
