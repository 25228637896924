<template>
  <div class="classe p-4">
    <div class="row">
      <div class="col-8 dotted mx-2"><Entete :title="entete_title" /></div>

      <div class="col dotted"><User /></div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="row dotted p-3">
          <div class="col-12 p-4 solid fw-bold">Emplois de temps</div>
        </div>
      </div>

      <div class="col">
        <div class="row dotted ml-2 p-4">
          <div class="solid row p-3 fw-bold">{{ classeInfos.libelle }}</div>
        </div>
      </div>
    </div>

    <div class="row dotted mt-4">
      <TimeTable />
    </div>
  </div>
</template>

<script>
import User from "@/components/dashboard/contentElements/Profil.vue";
import Entete from "@/components/dashboard/Entete.vue";
import Hour from "@/components/timeTable/Hour.vue";
import Course from "@/components/timeTable/Course.vue";
import TimeTable from "@/components/timeTable/TimeTable.vue";
export default {
  components: { User, Entete, Hour, Course, TimeTable },
  data() {
    return {
      days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
    };
  },

  computed: {
    datas() {
      return this.$store.state.classes.planning;
    },
    classeInfos() {
      return this.$store.state.classes.classe;
    },
  },
  mounted() {
    this.$store
      .dispatch("classes/getOneClasse", this.$route.params.id)
      .then((res) => {
        console.log("res  ", res);
      });

    let planing = this.$store.state.classes.planning;
    console.table(planing);
  },
};
</script>

<style scoped>
.day {
  background-color: #7b61ff;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}
.dotted {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px dashed #7b61ff;
  border-radius: 24px;
}

.solid {
  box-sizing: border-box;

  /* width: 200px;
  height: 100px; */

  /* Base/background white */

  background: #ffffff;
  padding: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

.solid1 {
  box-sizing: border-box;

  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #eaecee;
  border-radius: 12px;
}

select {
  background-color: #f9f9f9;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  /* width: 70%; */
  padding: 6px;
}

.input-group-text {
  background-color: white;
}

.btn-color {
  background-color: #7b61ff;
  border-radius: 5px;
  border: none;
  color: #ffffff;
}

.input {
  background-color: #e7e2e2;
}
</style>
