import axios from "axios";

export default {
  namespaced: true,

  state: {
    accounts: [],
    classAccounts: [],

    cardStats: [
      {
        title: "classe la plus couteuse",
        subTitle: "Tle D11",
        value: 1200000,
        currency: "Fcfa",
      },
      {
        title: "classe la moins couteuse",
        subTitle: "6ème M1",
        value: 700000,
        currency: "Fcfa",
      },
      {
        title: "classe la plus couteuse",
        subTitle: "6ème M1",
        value: 200000,
        currency: "Fcfa",
      },
    ],
  },
  getters: {
    getAllAccounts: (state) => {
      return state.accounts;
    },

    getClassAccounts: (state) => {
      return state.classAccounts;
    },
  },
  mutations: {
    setAccounts(state, data) {
      state.accounts = data;
    },

    setClassAccounts(state, data) {
      state.classAccounts = data;
    },

    setRecap(state, data) {
      function getClassCost(coursesList) {
        let cost = 0;
        coursesList.forEach((elt) => {
          cost = cost + elt.prix_enseignant * elt.nombre_heure;
        });

        return cost;
      }

      let tempo = [];
      data.forEach((item) => {
        let obj = {
          subTitle: item.classe.libelle,
          value: getClassCost(item.matieres),
          currency: "Fcfa",
        };
        tempo.push(obj);
      });

      tempo.sort((a, b) => a.value - b.value);

      state.cardStats = [
        {
          ...tempo[tempo.length - 1],
          title: "classe la plus couteuse",
        },
        { ...tempo[0], title: "classe la moins couteuse" },
      ];
    },
  },
  actions: {
    getGeneralAccountingDetails({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("compta")
          .then((res) => {
            console.log("res compta ", res.data);
            commit("setRecap", res.data);
            commit("setAccounts", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getClasseAccountingDetatails({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`compta/${id}`)
          .then((res) => {
            commit("setClassAccounts", res);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
