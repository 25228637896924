<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <!-- eslint-disable vue/valid-v-slot -->
  <DataTable
    v-if="data.length && columns.length"
    ref="dt"
    :value="data"
    dataKey="id"
    :paginator="true"
    :rows="10"
    :filters="filters"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[5, 10, 25]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
    responsiveLayout="scroll"
  >
    <template #header>
      <div
        class="
          table-header
          flex flex-column
          md:flex-row md:justiify-content-between
        "
      >
        <h5 class="mb-2 md:m-0 p-as-md-center">Liste des Classes</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            placeholder="Search..."
          />
        </span>
      </div>
    </template>

    <Column
      v-for="col in columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :sortable="col.sortable"
    >
      <template v-if="col.field == 'goTo'" #body="slotProps">
        <Button
          class="p-button-rounded p-button-text"
          icon="pi pi-external-link"
          iconPos="right"
          @click="openClassAccounting(slotProps.data.id)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

export default {
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: {},
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    console.log(this.data, this.columns);
  },
  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    openClassAccounting(id) {
      this.$router.push({ name: "classAccounting", params: { id } });
    },
  },
};
</script>
