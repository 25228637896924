import axios from "axios";

export default {
  namespaced: true,

  state: {
    Courses: [],
    CoursesFilter: [],
  },
  getters: {
    getAllCourses: (state) => {
      return state.Courses;
    },

    getfilterCourses: (state) => {
      return state.CoursesFilter;
    },
  },
  mutations: {
    setOneCourses(state, data) {
      state.Courses.unshift(data);
    },

    setCourses(state, data) {
      state.Courses = data;
    },

    setUpdateCourses(state, data) {
      state.Courses.forEach((element, index) => {
        if (element.id === data.id) state.Courses[index] = data.data;
      });
    },

    setDeleteCourses(state, id) {
      state.Courses = state.Courses.filter((item) => {
        return item.id !== id;
      });
    },

    setSearch(state, key) {
      console.log(key);
      state.CoursesFilter = state.Courses.filter((item) => {
        return item.libelle.includes(key);
      });
    },
  },
  actions: {
    createCourse({ commit }, data) {
      console.log(data);
      return axios.post("matieres", data).then((res) => {
        commit("setOneCourses", res.data);
        return res;
      });
    },

    allCourses({ commit }, data) {
      return axios.get("matieres").then((res) => {
        console.log("courses ", res.data);
        commit("setCourses", res.data);
        return res;
      });
    },

    updateCourse({ commit }, data) {
      return axios.put(`matieres/${data.id}`, data.data).then((res) => {
        let infos = { data: res.data, id: data.id };
        commit("setUpdateCourses", infos);
        return res;
      });
    },

    deleteCourse({ commit }, id) {
      return axios.delete(`matieres/${id}`).then((res) => {
        commit("setDeleteCourses", id);
        return res;
      });
    },
  },
};
