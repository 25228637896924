<template>
  <div class="header-rigth">
    <div class="row">
      <div class="col-2">
        <img class="size-img" :src="data.img" alt="" />
      </div>
      <div class="col">
        <p>{{ data.type }}</p>
        <span class="fw-bold">{{ data.name }}</span>

        <h6 class="text-danger over" @click="handleDeconnexion">
          <p>Deconnexion</p>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      data: {
        type: "Admin",
        name: "Bogne Stanley",
        img: require("@/assets/img/profile.png"),
      },
    };
  },

  methods: {
    handleDeconnexion() {
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.size-img {
  width: 70px;
  height: 70px;

  /* transform: translate(10px); */
  transform: translateY(12px);
}

.over {
  cursor: pointer;
}
</style>
